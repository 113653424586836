import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faTiktok, faGithub, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faMinus } from '@fortawesome/free-solid-svg-icons'


import './Footer.css'
import logo from '../../assets/img/logo.png'

const Footer = () => {
    return (
        // <section className='footer'>
        //     <div className='footer_wrapper'>
        //         <div className='footer_top'>
        //             <h1 className='footer_title'>Ready to enhance your success with AI?</h1>
        //             <div className='btn_container'>
        //                 <Link to={'/contact'} className='demo_btn'>Book a Demo</Link>
        //                 <Link to={'/services'} className='service_btn'>Explore Our Services</Link>
        //             </div>
        //         </div>
        //         <div className='footer_bottom'>
        //             <div className='left_side'>
        //                 <img className='footer_logo' src={logo} alt='Company Logo' />
        //                 <h1 className='footer_subtitle'>Your Partner for Success</h1>
        //             </div>
        //             <div className='right_side'>
        //                 <ul className='footer_nav_container'>
        //                     <li className='footer_nav_item'><Link to={'/'} className='footer_nav_link'>Home</Link></li>
        //                     <li className='footer_nav_item'><Link to={'/about'} className='footer_nav_link'>About</Link></li>
        //                     <li className='footer_nav_item'><Link to={'/projects'} className='footer_nav_link'>Projects</Link></li>
        //                     <li className='footer_nav_item'><Link to={'/technologies'} className='footer_nav_link'>Technologies</Link></li>
        //                     <li className='footer_nav_item'><Link to={'/services'} className='footer_nav_link'>Services</Link></li>
        //                 </ul>

        //                 <ul className='footer_nav_container'>
        //                     <li className='footer_nav_item'><Link to={'/industries'} className='footer_nav_link'>Industries</Link></li>
        //                     <li className='footer_nav_item'><Link to={'/case-studies'} className='footer_nav_link'>Case Studies</Link></li>
        //                     <li className='footer_nav_item'><Link to={'/contact'} className='footer_nav_link'>Contact Us</Link></li>
        //                 </ul>

        //                 <ul className='footer_nav_container'>
        //                     <li className='footer_nav_item'><p className='lets_chat'>Let's Chat!</p></li>
        //                     <li className='footer_nav_item'><a href='mailto:hr@neurooceans.ai' className='footer_nav_link'>hr@neurooceans.ai</a></li>
        //                     <div>

        //                         <a className="logo_container mx-1 pt-1" href="https://github.com/NeurOOceans">
        //                             <FontAwesomeIcon className="logo" icon={faGithub} style={{ color: "#ffffff", }} />
        //                         </a>
        //                         <a className="logo_container mx-1 pt-1" href="https://twitter.com/neurooceansai">
        //                             <FontAwesomeIcon className="logo" icon={faXTwitter} style={{ color: "#ffffff", }} />
        //                         </a>
        //                         <a className="logo_container mx-1 pt-1" href="https://pk.linkedin.com/company/neurooceans-ai">
        //                             <FontAwesomeIcon className="logo" icon={faLinkedinIn} style={{ color: "#ffffff", }} />
        //                         </a>
        //                         <a className="logo_container mx-1 pt-1" href="https://www.tiktok.com/@neurooceans.ai">
        //                             <FontAwesomeIcon className="logo" icon={faTiktok} style={{ color: "#ffffff", }} />
        //                         </a>
        //                         <a className="logo_container mx-1 pt-1" href="https://www.instagram.com/neurooceansai/">
        //                             <FontAwesomeIcon className="logo" icon={faInstagram} style={{ color: "#ffffff", }} />
        //                         </a>

        //                     </div>
        //                 </ul>
        //             </div>
        //         </div>
        //     </div>
        // </section>

        <section className='footer'>
            <div className='footer_wrapper'>
                <hr className='footer_line' />
                <h1 className='footer_title'>Ready to enhance your success with AI?</h1>

                <div className='footer_content'>
                    <div className='nav'>
                        <ul>
                            <li className='footer_nav_item'>
                                <Link to={'/'} className='footer_nav_link'>
                                    <FontAwesomeIcon icon={faMinus} />Home
                                </Link>
                            </li>

                            <li className='footer_nav_item'>
                                <Link to={'/about'} className='footer_nav_link'>
                                    <FontAwesomeIcon icon={faMinus} />About
                                </Link>
                            </li>

                            <li className='footer_nav_item'>
                                <Link to={'/projects'} className='footer_nav_link'>
                                    <FontAwesomeIcon icon={faMinus} />Projects
                                </Link>
                            </li>

                            <li className='footer_nav_item'>
                                <Link to={'/technologies'} className='footer_nav_link'>
                                    <FontAwesomeIcon icon={faMinus} />Technologies
                                </Link>
                            </li>

                            <li className='footer_nav_item'>
                                <Link to={'/services'} className='footer_nav_link'>
                                    <FontAwesomeIcon icon={faMinus} />Services
                                </Link>
                            </li>

                            <li className='footer_nav_item'>
                                <Link to={'/industries'} className='footer_nav_link'>
                                    <FontAwesomeIcon icon={faMinus} />Industries
                                </Link>
                            </li>

                            <li className='footer_nav_item'>
                                <Link to={'/case-studies'} className='footer_nav_link'>
                                    <FontAwesomeIcon icon={faMinus} />Case Studies
                                </Link>
                            </li>

                            <li className='footer_nav_item'>
                                <Link to={'/contact'} className='footer_nav_link'>
                                    <FontAwesomeIcon icon={faMinus} />Contact Us
                                </Link>
                            </li>

                        </ul>
                    </div>
                    <div className='saperator'></div>
                    <div className='lets_chat'>
                        <h1>Let's chat:</h1>
                        <div className='contact phone'>
                            <FontAwesomeIcon icon={faPhone} />
                            <Link to={'tel:%20+971507278147'}>+971 50 727 8147</Link>
                        </div>
                        <div className='contact'>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <Link to={'mailto:hr@neurooceans.ai'}>hr@neurooceans.ai</Link>
                        </div>
                    </div>
                    <div className='saperator'></div>
                    <div className='follow_us'>
                        <h1>Follow Us on:</h1>
                        <div>

                            <a className="logo_container" href="https://github.com/NeurOOceans">
                                <FontAwesomeIcon className="logo" icon={faGithub} />
                            </a>
                            <a className="logo_container" href="https://twitter.com/neurooceansai">
                                <FontAwesomeIcon className="logo" icon={faXTwitter} />
                            </a>
                            <a className="logo_container" href="https://pk.linkedin.com/company/neurooceans-ai">
                                <FontAwesomeIcon className="logo" icon={faLinkedinIn} />
                            </a>
                            <a className="logo_container" href="https://www.tiktok.com/@neurooceans.ai">
                                <FontAwesomeIcon className="logo" icon={faTiktok} />
                            </a>
                            <a className="logo_container" href="https://www.instagram.com/neurooceansai/">
                                <FontAwesomeIcon className="logo" icon={faInstagram} />
                            </a>

                        </div>
                    </div>
                </div>

                <hr className='footer_line' />

                <div className='footer_end'>
                    <p>&#169; neurooceans.ai 2024</p>
                </div>

            </div>
        </section>
    )
}

export default Footer