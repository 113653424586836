import React from 'react'

import HeroSection from '../../components/heroSection/Herosection';
import App from '../../components/customers/Customers'
import './About.css'
import '../home/Home.css'

const About = () => {
  return (
    <div className='about_page'>
      <HeroSection title="About" />

      <div className='about_container'>

        <div className='header'>
          <h1 className="page_title">About Our Company</h1>
          <div className='border'></div>
          <p className="subtitle"> Discover Our Mission, Values, and Why You Should Choose Us </p>
        </div>

        <div className='content_wrapper'>

          <div className='company_image'>
            <img src='/assets/know-us.jpg' alt='about' />
          </div>

          <div className='description'>
            <h1 className='title'>Get to Know Us</h1>
            <p className='text_bold'>NeuroOceans AI is a trusted provider of AI solutions, helping businesses across industries use the power of artificial intelligence to improve decision-making, boost efficiency, and stay ahead of the competition.</p>
            <p className='text_normal'>We understand the unique challenges companies face and offer tailored AI solutions to meet their specific needs. Our mission is to help businesses grow, increase profitability, and enhance customer satisfaction by harnessing AI's full potential.</p>
            <p className='text_normal'>At NeuroOceans AI, our team is dedicated to delivering the best results with unwavering loyalty and commitment. We strive to exceed expectations, making us a trusted partner for businesses looking to thrive in an AI-driven world.</p>
          </div>
        </div>

        <div className='content_wrapper reverse'>

          <div className='company_image'>
            <img src='/assets/our_vision.jpg' alt='about' />
          </div>

          <div className='description'>
            <h1 className='title'>Our Vision</h1>
            <p className='text_normal'>At NeuroOceans AI, our vision is to revolutionize industries by integrating AI into every operation of a company. We aim to deliver what others cannot and are committed to being pioneers in AI innovation, embracing changes that support sustainable growth. Our goal is to empower businesses to achieve unmatched success.</p>
            <p className='text_normal'>We strive to be the catalyst that accelerates growth and efficiency, keeping our clients at the forefront of their industries. In a crowded market, we ensure our clients stand out and shine brighter than the competition. We are dedicated to shaping a future where AI seamlessly enhances every business process and experience.</p>
          </div>
        </div>

        <div className='content_wrapper'>

          <div className='company_image'>
            <img src='/assets/what-we-believe.png' alt='about' />
          </div>

          <div className='description'>
            <h1 className='title'>What We Believe</h1>
            <p className='text_normal'>At NeuroOceans AI, we believe in building strong, long-lasting partnerships with our clients, grounded in trust, transparency, and collaboration. Our commitment extends beyond delivering innovative AI solutions. We prioritize understanding each client’s unique challenges and goals, ensuring we tailor our services to drive real value.</p>
            <p className='text_normal'>We celebrate the transformative power of AI, striving to be leaders in its continuous evolution. Our dedication to innovation pushes us to stay ahead of the curve, offering our clients the tools to not only compete but to excel in their industries. We are committed to delivering excellence that enhances our clients' growth, reputation, and long-term success.</p>
            <p className='text_normal'>We see the future alongside our clients, where AI is seamlessly integrated into every process, creating lasting value and fostering sustainable growth. Together, we embrace the opportunities ahead, driven by the belief that AI holds the key to a brighter, more efficient tomorrow.</p>
          </div>
        </div>

        <div className='why_us'>
          <h1 className='title'>Why Choose NeuroOceans AI</h1>
          <p className='sub_title'>We create 360-degree solutions that target and simplify multiple organizational challenges, all at once!</p>

          <div className='why_us_desc'>

            <div className='desc_image'>
              <img src='/assets/quality_first.webp' alt='about' />
            </div>

            <div className='description'>
              <h2>A quality-first company</h2>
              <p className='text_normal'>At NeuroOceans AI, we specialize in delivering top-tier software solutions and services tailored to meet the unique needs of our clients. Our commitment is to ensure that every investment in IT yields tangible business value. We pride ourselves on fostering healthy project processes, driven by professional and motivated teams, facilitating effective communication between NeuroOceans AI, your teams, and stakeholders.</p>
            </div>
          </div>

          <div className='why_us_desc'>

            <div className='desc_image'>
              <img src='/assets/fast_stable.webp' alt='about' />
            </div>

            <div className='description'>
              <h2>Fast development while retaining application stability</h2>
              <p className='text_normal'>At NeuroOceans AI, we've curated a suite of management and technological strategies enabling us to roll out fresh application iterations every 2–3 weeks, with the flexibility to implement minor updates multiple times daily. Our success stems from an iterative development ethos, bolstered by Agile project management methodologies, and underpinned by cloud-native architectures and cutting-edge infrastructure management techniques.</p>
            </div>
          </div>

          <div className='why_us_desc'>

            <div className='desc_image'>
              <img src='/assets/commitment.webp' alt='about' />
            </div>

            <div className='description'>
              <h2>Commitment to excellence</h2>
              <p className='text_normal'>At NeuroOceans AI, we delve beyond conventional strategies to elevate customer satisfaction and enhance performance through our cutting-edge IT services and innovative solutions. Our proficiency across four crucial dimensions: Customer References, Delivery Excellence (Awards & Certifications), Programs for Innovation, and Corporate Social Responsibility.</p>
            </div>
          </div>

          <div className='why_us_desc'>

            <div className='desc_image'>
              <img src='/assets/top_security.webp' alt='about' />
            </div>

            <div className='description'>
              <h2>Top-notch security</h2>
              <p className='text_normal'>With 10 years of dedicated service in the realm of IT, NeuroOceans AI stands as a beacon of reliability and security. Throughout our journey, not a single breach has compromised the trust of our clients. We pledge unwavering protection for our customers' invaluable data. At NeuroOceans AI, we spare no effort in fortifying our defenses. Our strategy hinges on diligent IT asset management, fostering a secure environment, enforcing strict access controls, and employing formidable encryption algorithms. Continuous monitoring of our IT infrastructure is paramount, ensuring swift detection and response to any potential threats.</p>
            </div>
          </div>

        </div>

      </div>
      <App />
    </div>
  )
}

export default About
