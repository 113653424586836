import React, { useState, useEffect } from "react";
import { PulseLoader } from "react-spinners";

import HeroSection from '../../components/heroSection/Herosection';
import App from '../../components/customers/Customers';
import './CaseStudies.css'
import CasesCard from "../../components/use_cases/CasesCard";


const Casestudies = () => {

  const [visibleCases, setVisibleCases] = useState(4);
  const [caseData, setCaseData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://travel-and-tourism-ffaa0e14932d.herokuapp.com/api/Neuroocean-AI/case-studies/");
        const data = await response.json();
        setCaseData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    return () => {
    };
  }, []);


  // Display loading message until data is fetched
  // if (isLoading) {
  //   return <div className="loading">Loading...</div>;
  // }

  if (isLoading) {
    return (
      <div className="not_found_or_loading">
        <PulseLoader
          margin={5}
          size={20}
          speedMultiplier={1}
        />
      </div>
    );
  }

  const loadMore = () => {
    setVisibleCases(prevVisibleCases => prevVisibleCases + 2);
  };

  const loadLess = () => {
    setVisibleCases(2);
  };


  return (
    <div className='casestudies_page'>
      <HeroSection title="Case Studies" />

      <div className='casestudies_container'>

        <div className='header'>
          <h1 className="page_title"><b>Success Stories</b> We Co-Created with Our Clients</h1>
          <div className='border'></div>
          <p className="subtitle"> At Edvantis we understand that to sustain the high quality of delivery
            we have to focus on forging win-win partnerships with our Clients. By
            combining our technical expertise and 20+ years of operational
            experience with the Clients’ product vision and domain knowledge, we
            maximize the success of every project. Learn more about the results we
            achieved together! </p>
        </div>

        <section className="cases_container">

          <section className="case_studies_posts">

            <div className="card_wrapper">
              {caseData.slice().reverse().slice(0, visibleCases).map((member, index) => (
                <CasesCard 
                  slug = {member.slug}
                  index = {index}
                  image = {member.image}
                  title = {member.title}
                  tags = {member.tags}
                  desc = {member.meta_description}
                />
              ))}
            </div>

            {visibleCases < caseData.length ? (
              <button className="loading_btn" onClick={loadMore}>Load More</button>
            ) : (
              <button className="loading_btn" onClick={loadLess}>Load Less</button>
            )}
          </section>
        </section>
      </div>

      <App />

    </div>
  )
}

export default Casestudies
