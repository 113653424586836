import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import { faTiktok, faXTwitter, faLinkedinIn, faInstagram, faGithub } from '@fortawesome/free-brands-svg-icons';
import HeroSection from '../../components/heroSection/Herosection';
import axios from 'axios';
import './Career.css';
import { Link, useParams } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';

const JobDescription = () => {
    const { slug } = useParams();
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        const fetchJobsData = async () => {
            try {
                const response = await axios.get("https://travel-and-tourism-ffaa0e14932d.herokuapp.com/api/Neuroocean-AI/career/jobs/");
                setJobs(response.data);
                setLoading(false);
            } catch (error) {
                console.error("There was an error fetching the jobs data:", error);
                setLoading(false);
            }
        };
        fetchJobsData();
    }, []);

    const job = jobs.find(job => job.slug === slug);

    if (loading) {
        return (
            <div className="not_found_or_loading">
                <PulseLoader 
                    margin={5}
                    size={20}
                    speedMultiplier={1}
                />
            </div>
        );
    }

    if (!job) {
        return <div className='not_found_or_loading'>Job not found, or might be expired</div>;
    }

    return (
        <div className='job_desc_page'>
            <HeroSection title="Career" />
            <div className='desc_container'>
                <div className='header'>
                    <div>
                        <h1 className="title">{job.title}</h1>
                        <p><span className='category'>{job.category.name}</span> -- <span className='location'>{job.location}</span></p>
                    </div>
                    <Link to={`/apply/jobs/${job.slug}`}>
                        <button className='apply_btn'>APPLY NOW</button>
                    </Link>
                </div>
                <hr className='header_separator' />

                <div className='content_wrapper'>
                    <div className='content'>
                        <div className='job_data'>
                            <h2 className='sub_title'>Summary:</h2>
                            <p className='data'>{job.summary}</p>
                        </div>

                        <div className='job_data'>
                            <h2 className='sub_title'>Responsibilities:</h2>
                            <ul>
                                {job.responsibility.map((responsibility, index) => (
                                    <li className='data' key={index}><FontAwesomeIcon icon={faMinus} /> {responsibility}</li>
                                ))}
                            </ul>
                        </div>

                        <div className='job_data'>
                            <h2 className='sub_title'>Requirements:</h2>
                            <ul>
                                {job.requirements.map((requirement, index) => (
                                    <li className='data' key={index}><FontAwesomeIcon icon={faMinus} /> {requirement}</li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className='about_us'>
                        <h2 className='heading'>Join Our Community</h2>
                        <div className='logos_wrapper'>
                            <a className="logo_container" href="https://github.com/NeurOOceans">
                                <FontAwesomeIcon className="logo" icon={faGithub} />
                            </a>
                            <a className="logo_container" href="https://twitter.com/neurooceansai">
                                <FontAwesomeIcon className="logo" icon={faXTwitter} />
                            </a>
                            <a className="logo_container" href="https://pk.linkedin.com/company/neurooceans-ai">
                                <FontAwesomeIcon className="logo" icon={faLinkedinIn} />
                            </a>
                            <a className="logo_container" href="https://www.tiktok.com/@neurooceans.ai">
                                <FontAwesomeIcon className="logo" icon={faTiktok} />
                            </a>
                            <a className="logo_container" href="https://www.instagram.com/neurooceansai/">
                                <FontAwesomeIcon className="logo" icon={faInstagram} />
                            </a>
                        </div>

                        <h2 className='heading'>About Us</h2>
                        <p className='para'>At NeuroOceans AI, we excel in designing and engineering robust software solutions using mobile, web, and cloud technologies. We specialize in developing custom web applications, intuitive mobile apps, and advanced data mining techniques, creating digital solutions that make a significant impact. We believe in the potential of innovation and the possibilities it brings.</p>
                        <br />
                        <p className='para'>Our team comprises over 900 skilled software engineers, developers, UX architects, designers, and QA engineers, all dedicated to delivering high-impact software. Over the past decade, we have developed thousands of solutions, enabling businesses to scale rapidly. We recognize the challenges of working with a remote team and strive to provide an experience that feels as seamless as having a development team on-site. Share your vision with us, and we will bring it to life.</p>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobDescription;
