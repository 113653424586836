import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import HeroSection from '../../components/heroSection/Herosection';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';

import './Career.css';

const ApplyForJob = () => {
    const { slug } = useParams();
    const [jobs, setJobs] = useState([]);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [submiting, setSubmiting] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        address: '',
        gender: '',
        resume: null
    });

    const formRefs = {
        firstName: useRef(null),
        lastName: useRef(null),
        email: useRef(null),
        phone: useRef(null),
        address: useRef(null),
        gender: useRef(null),
        resume: useRef(null)
    };

    // fetching jobs data
    useEffect(() => {
        const fetchJobsData = async () => {
            try {
                const response = await axios.get("https://travel-and-tourism-ffaa0e14932d.herokuapp.com/api/Neuroocean-AI/career/jobs/");
                setJobs(response.data);
                setLoading(false);
            } catch (error) {
                console.error("There was an error fetching the jobs data:", error);
                setLoading(false);
            }
        }
        fetchJobsData();
    }, []);

    // filtering specific job from job data
    const job = jobs.find(job => job.slug === slug);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: ''
            });
        }
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            resume: e.target.files[0]
        });
        if (errors.resume) {
            setErrors({
                ...errors,
                resume: ''
            });
        }
    };

    // validating form data, so that correct data must be posted
    const validateForm = () => {
        const newErrors = {};

        if (!formData.firstName) newErrors.firstName = 'First Name is required';

        if (!formData.lastName) newErrors.lastName = 'Last Name is required';

        if (!formData.email) {
            newErrors.email = 'Email is required';
        }
        else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Invalid email format';
        }

        if (!formData.phone) {
            newErrors.phone = 'Phone Number is required';
        } else if (!/^\+?[\d\s.-]{11,}$/.test(formData.phone)) {
            newErrors.phone = 'Invalid phone number format';
        }

        if (!formData.address) newErrors.address = 'Address is required';

        if (!formData.gender) newErrors.gender = 'Please select your gender';

        if (formData.resume) {
            const allowedTypes = [
                'application/msword',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                'application/pdf'
            ];

            if (!allowedTypes.includes(formData.resume.type)) {
                newErrors.resume = 'File must be in .doc, .docx, or .pdf format';
            }

            if (formData.resume.size > 2 * 1024 * 1024) {
                newErrors.resume = 'File must be less than 2MBs';
            }
        } else {
            newErrors.resume = 'Please upload your resume';
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        setErrors(validationErrors);

        // By using useRef screen will scroll to first field were error is encountring
        if (Object.keys(validationErrors).length > 0) {
            const firstErrorElement = formRefs[Object.keys(validationErrors)[0]].current;

            if (firstErrorElement) {

                // as navbar is position: "fixed", screen will scroll to first error field including height of navbar.
                const navbarHeight = -140;
                const spaceFromTop = firstErrorElement.getBoundingClientRect().top + window.scrollY + navbarHeight;

                window.scrollTo({ top: spaceFromTop, behavior: 'smooth' });
            }
            return;
        }

        setSubmiting(true)
        // appending form data
        const form_data = new FormData();
        form_data.append('first_name', formData.firstName);
        form_data.append('last_name', formData.lastName);
        form_data.append('email', formData.email);
        form_data.append('phone', formData.phone);
        form_data.append('address', formData.address);
        form_data.append('gender', formData.gender);
        form_data.append('resume', formData.resume);
        form_data.append('job', job.category.id);

        // sending form data to API
        try {
            const response = await axios.post('https://travel-and-tourism-ffaa0e14932d.herokuapp.com/api/Neuroocean-AI/career/applications/',
                form_data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            );

            setSubmiting(false)
        } catch (error) {
            console.error("Error submitting application:", error.response.data);
            setSubmiting(false)
        }
    };

    const handleDropZoneClick = () => {
        document.getElementById('resume').click();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setFormData({
            ...formData,
            resume: e.dataTransfer.files[0]
        });
        if (errors.resume) {
            setErrors({
                ...errors,
                resume: ''
            });
        }
    };

    if (loading) {
        return (
            <div className="not_found_or_loading">
                <PulseLoader
                    margin={5}
                    size={20}
                    speedMultiplier={1}
                />
            </div>
        );
    }

    if (!job) {
        return <div className='not_found_or_loading'>Job not found, or might be expired</div>;
    }

    return (
        <div className='apply_page'>
            <HeroSection title="Career" />
            <div className='desc_container'>
                <div className='header'>
                    <div>
                        <h1 className="title">{job.title}</h1>
                        <p><span className='category'>{job.category.name}</span> -- <span className='location'>{job.location}</span></p>
                    </div>
                </div>
                <hr className='header_separator' />
                <div className='job_form'>
                    <h2 className='form_title'>Job Application</h2>

                    <form className='form' onSubmit={handleSubmit}>

                        <div className='form_group'>
                            <input
                                type='text'
                                id='firstName'
                                name='firstName'
                                placeholder='First Name*'
                                style={errors.firstName ? { borderColor: 'red' } : {}}
                                value={formData.firstName}
                                onChange={handleChange}
                                ref={formRefs.firstName}
                            />
                            {errors.firstName && <p className='error_line'>{errors.firstName}</p>}
                        </div>

                        <div className='form_group'>
                            <input
                                type='text'
                                id='lastName'
                                name='lastName'
                                placeholder='Last Name*'
                                style={errors.lastName ? { borderColor: 'red' } : {}}
                                value={formData.lastName}
                                onChange={handleChange}
                                ref={formRefs.lastName}
                            />
                            {errors.lastName && <p className='error_line'>{errors.lastName}</p>}
                        </div>

                        <div className='form_group'>
                            <input
                                type='text'
                                id='email'
                                name='email'
                                placeholder='Email*'
                                style={errors.email ? { borderColor: 'red' } : {}}
                                value={formData.email}
                                onChange={handleChange}
                                ref={formRefs.email}
                            />
                            {errors.email && <p className='error_line'>{errors.email}</p>}
                        </div>

                        <div className='form_group'>
                            <input
                                type='tel'
                                id='phone'
                                name='phone'
                                placeholder='+92 012 3456789'
                                style={errors.phone ? { borderColor: 'red' } : {}}
                                value={formData.phone}
                                onChange={handleChange}
                                ref={formRefs.phone}
                            />
                            {errors.phone && <p className='error_line'>{errors.phone}</p>}
                        </div>

                        <div className='form_group'>
                            <input
                                type='text'
                                id='address'
                                name='address'
                                placeholder='Address*'
                                style={errors.address ? { borderColor: 'red' } : {}}
                                value={formData.address}
                                onChange={handleChange}
                                ref={formRefs.address}
                            />
                            {errors.address && <p className='error_line'>{errors.address}</p>}
                        </div>

                        <div className='form_group'>
                            <select
                                id='gender'
                                name='gender'
                                style={errors.gender ? { borderColor: 'red' } : {}}
                                value={formData.gender}
                                onChange={handleChange}
                                ref={formRefs.gender}
                            >
                                <option value=''>Gender</option>
                                <option value='Male'>Male</option>
                                <option value='Female'>Female</option>
                            </select>
                            {errors.gender && <p className='error_line'>{errors.gender}</p>}
                        </div>
                        <div
                            className='drop_zone'
                            style={errors.resume ? { borderColor: 'red' } : {}}
                            onDrop={handleDrop}
                            onDragOver={(e) => e.preventDefault()}
                            onClick={handleDropZoneClick}
                            ref={formRefs.resume}
                        >
                            <input
                                type='file'
                                accept='.docx, .pdf'
                                id='resume'
                                name='resume'
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                            />
                            <div className='drop_zone_text'>
                                {formData.resume ? <p className='file_name'>{formData.resume.name}</p> : <p>Drag and drop file (.docx or .pdf) here, or</p>}
                                <button className='drop_btn' type='button'>Upload CV/Resume (less than 2MB)</button>
                            </div>
                        </div>
                        {errors.resume && <p className='error_line'>{errors.resume}</p>}

                        <button type='submit' disabled={submiting}>{submiting ? 'Submiting...' : 'Submit Application'}</button>

                    </form>
                </div>
            </div >
        </div >
    );
};

export default ApplyForJob;
