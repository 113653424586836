import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTableCells, faList, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';

import HeroSection from '../../components/heroSection/Herosection';
import './Career.css';


const Career = () => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [listView, setListView] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchJobsData = async () => {
      try {
        const response = await axios.get("https://travel-and-tourism-ffaa0e14932d.herokuapp.com/api/Neuroocean-AI/career/jobs/");
        setJobs(response.data);
        setLoading(false);
      } catch (error) {
        console.error("There was an error fetching the jobs data:", error);
        setLoading(false);
      }
    };
    fetchJobsData();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategories(prev =>
      prev.includes(category)
        ? prev.filter(cat => cat !== category)
        : [...prev, category]
    );
  };

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const toggleListView = () => {
    setListView(true);
  };

  const toggleGridView = () => {
    setListView(false);
  };

  const categories = jobs.reduce((acc, job) => {
    acc[job.category.name] = (acc[job.category.name] || 0) + 1;
    return acc;
  }, {});

  const filteredJobs = jobs.filter(job => {
    const matchesCategory = selectedCategories.length ? selectedCategories.includes(job.category.name) : true;
    const matchesSearch = job.title.toLowerCase().includes(searchValue.toLowerCase()) ||
      job.category.name.toLowerCase().includes(searchValue.toLowerCase());

    return matchesCategory && matchesSearch;
  });

  if (loading) {
    return (
      <div className="not_found_or_loading">
        <PulseLoader
          margin={5}
          size={20}
          speedMultiplier={1}
        />
      </div>
    );
  }

  return (
    <div className='career_page'>
      <HeroSection title="Career" />
      <div className='career_container'>
        <div className='header'>
          <h1 className="page_title">Join Our Inclusive and Innovative Team</h1>
          <div className='border'></div>
          <p className="subtitle">At NeuroOceans AI, we are dedicated to fostering a culture of inclusivity and diversity, where every team member can thrive. Join us in our mission to make AI more accessible for all.</p>
        </div>

        <hr className='position_separator' />
        <h1 className="position_title">JOB POSTS</h1>

        {jobs.length === 0 ? <div className='no_job_post'>No job posts are currently open.</div> : <div className='position_wrapper'>

          <div className='category_wrapper'>
            <p><b>SHOW</b> RESULTS BY</p>
            <div className='category'>
              <ul>

                {Object.keys(categories).map((category) => (
                  <li className='category_name' key={category}>
                    <input
                      className='check_box'
                      type="checkbox"
                      id={category}
                      onChange={() => handleCategoryChange(category)}
                    />
                    <label htmlFor={category}> {category} ({categories[category]})</label>
                  </li>
                ))}

              </ul>
            </div>
          </div>

          <div className='post_wrapper'>
            <div className='top_bar'>
              <p className='bar_title'><b>ALL</b> POSITIONS</p>
              <p className='total_jobs'>{filteredJobs.length} Job(s)</p>
              <div className='bar_icon_container'>
                <FontAwesomeIcon className={`bar_icon ${listView ? 'black' : ''}`} onClick={toggleGridView} icon={faTableCells} />
                <FontAwesomeIcon className={`bar_icon ${listView ? '' : 'black'}`} onClick={toggleListView} icon={faList} />
              </div>
              <div className='search_wrapper'>
                <input
                  type="text"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleSearchValue}
                  className='search_input'
                />
                <FontAwesomeIcon className='search_icon' icon={faMagnifyingGlass} />
              </div>
            </div>
            <div className={`posts ${listView ? 'list_view' : ''}`}>

              {filteredJobs.map((job, index) => (
                <Link to={`/jobs/${job.slug}`} className='post' key={index}>
                  {/* {job.featured && <div className='featured'>Featured</div>} */}
                  <p className='job_title'>{job.title}</p>
                  <p className='job_positions'>{job.positions_available} Position(s)</p>
                  <hr className='job_separator' />
                  <p className='job_category'>{job.category.name}</p>
                  <p className='job_location'>{job.location}</p>
                </Link>
              ))}

            </div>
          </div>
        </div>
        }

      </div>
    </div>
  );
};

export default Career;
