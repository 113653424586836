import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

import HeroSection from '../../components/heroSection/Herosection';
import './Contact.css'
import '../home/Home.css'
import { Link, useNavigate } from 'react-router-dom';

const Contact = () => {
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   navigate('/thank-you')
  // }

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
      navigate('/thank-you');
    }, 2000);
  };

  return (
    <div className='contact_page'>
      <HeroSection title="Contact" />
      <div className='contact_container'>
        <div className='header'>
          <h1 className="page_title">Get in Touch with Us</h1>
          <div className='border'></div>
          <p className="subtitle"> We Appreciate Your Interest, contact us with your projects, ideas, and questions </p>
        </div>

        <div className='contact_wrapper'>

          <div className='contact_details'>
            <div className='header'>
              <p className='contact_subheading'>Come Visit Us At</p>
              <h1 className='contact_heading'>Our Address</h1>
              <div className='border'></div>
            </div>

            <div className='details_body'>

              <div className='widget_wrap'>
                <div className='widget_icon'>
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className='widget_content'>
                  <h3>Office Address</h3>
                  <h6 className='address_heading'>Dubai:</h6>
                  <p>ALRAJHI-BLDG, Hor Al Anz, Dubai, United Arab Emirates.</p>
                  <h6 className='address_heading'>Pakistan:</h6>
                  <p>1048 Shawala Rd, Block E1 Phase 1 Johar town, Lahore, Pakistan.</p>
                </div>
              </div>

              <div className='widget_wrap'>
                <div className='widget_icon'>
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className='widget_content'>
                  <h3>Phone Number</h3>
                  <Link to={'tel:%20+971507278147'}>+971 50 727 8147</Link>
                </div>
              </div>

              <div className='widget_wrap'>
                <div className='widget_icon'>
                  <FontAwesomeIcon icon={faWhatsapp} />
                </div>
                <div className='widget_content'>
                  <h3>Whatsapp</h3>
                  <Link to={'https://wa.me/971507278147'}>+971507278147</Link>
                </div>
              </div>

              <div className='widget_wrap' style={{ border: 'none' }}>
                <div className='widget_icon'>
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className='widget_content'>
                  <h3>Our Email</h3>
                  <Link to={'mailto:hr@neurooeans.ai'}>hr@neurooeans.ai</Link>
                </div>
              </div>

            </div>
          </div>

          <div className='contact_form'>
            <div className='content'>
              <div className='header'>
                <p className='contact_subheading'>Send Message</p>
                <h1 className='contact_heading'>Drop Us A Line</h1>
                <div className='border'></div>
              </div>

              <form className='form' onSubmit={handleSubmit}>
                <div className='form_row'>
                  <div className='input_div'>
                    <input placeholder='First Name*'></input>
                  </div>
                  <div className='input_div'>
                    <input placeholder='Last Name*'></input>
                  </div>
                </div>

                <div className='form_row'>
                  <div className='input_div'>
                    <input placeholder='Phone Number*'></input>
                  </div>
                  <div className='input_div'>
                    <input placeholder='Email*'></input>
                  </div>
                </div>

                <div className='text_box'>
                  <textarea rows={2} placeholder='Message'></textarea>
                </div>

                <div class="button">
                  <input type='submit' disabled={submitting} value={submitting ? 'Submitting...' : 'Submit Message'}></input>
                </div>
              </form>

            </div>
          </div>

        </div>

      </div>

      <div className='location'>
        <div className='location_wrapper'>
          <h3>Dubai office location:</h3>
          <div className='location_inner'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7215.620844108781!2d55.330604!3d25.276962!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5d25b5dc75bb%3A0x12938b11975f717e!2sNeuro%20Oceans%20Travel%20and%20Tourism!5e0!3m2!1sen!2sus!4v1729585538422!5m2!1sen!2sus"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade">
            </iframe>

            {/* Custom Place Name Overlay */}
            <div className='location_content'>
              <div>
                <h4>NeuroOceans AI</h4>
                <p>ALRAJHI-BLDG, Hor Al Anz, Dubai, United Arab Emirates.</p>
              </div>
              {/* <a href={'https://maps.google.com/maps?ll=31.471029,74.287469&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=17414808248786267063'} target="_blank">View large map</a> */}
              <a href={'https://maps.google.com/maps?ll=25.276962,55.330604&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=1338566421929488766'} target="_blank">View large map</a>
            </div>

          </div>
        </div>
        <div className='location_wrapper'>
          <h3>Pakistan office location:</h3>
          <div className='location_inner'>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3402.933025943407!2d74.28489427447141!3d31.471028474236576!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919030078cf9467%3A0xf1add3d5ac72abb7!2sNeuroOceans%20AI!5e0!3m2!1sen!2s!4v1719986974458!5m2!1sen!2s"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />

            <div className='location_content'>
              <div>
                <h4>NeuroOceans AI</h4>
                <p>1048 Shawala Rd, Block E1 Phase 1 Johar town, Lahore, Pakistan.</p>
              </div>
              <a href={'https://maps.google.com/maps?ll=31.471029,74.287469&z=16&t=m&hl=en&gl=US&mapclient=embed&cid=17414808248786267063'} target="_blank">View large map</a>
            </div>
          </div>
        </div>

      </div>


    </div>
  )
}

export default Contact
